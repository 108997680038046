@tailwind base;
@tailwind components;
@tailwind utilities;

/*Font Face JOST*/

@font-face {
  font-family: "Manrope";
  src: url("/public/font/Manrope.ttf");
}

.display-1 {
  font-family: "Manrope";
  font-size: 56px;
  line-height: 150%;
  letter-spacing: -0.05em;
}

.display-2 {
  font-family: "Manrope";
  font-size: 40px;
  line-height: 150%;
  letter-spacing: -0.05em;
}

.heading-1-m {
  font-family: "Manrope";

  font-size: 32px;
  line-height: 150%;
  letter-spacing: -0.02em;
}

.heading-1-r {
  font-family: "Manrope";

  font-size: 32px;
  line-height: 150%;
  letter-spacing: -0.02em;
}

.heading-2-m {
  font-family: "Manrope";

  font-size: 24px;
  line-height: 150%;
  letter-spacing: -0.02em;
}

.heading-2-r {
  font-family: "Manrope";

  font-size: 24px;
  line-height: 150%;
  letter-spacing: -0.02em;
}

.body-1-m {
  font-family: "Manrope";

  font-size: 18px;
  line-height: 150%;
}

.body-1-r {
  font-family: "Manrope";

  font-size: 18px;
  line-height: 180%;
}

.body-2-m {
  font-family: "Manrope";

  font-size: 16px;
  line-height: 150%;
}

.body-2-r {
  font-family: "Manrope";

  font-size: 16px;
  line-height: 150%;
}

.CTA-large {
  font-family: "Manrope";

  font-size: 16px;
  line-height: 150%;
  letter-spacing: 0.1em;
  text-transform: uppercase;
}

.CTA-medium {
  font-family: "Manrope";

  font-size: 14px;
  line-height: 150%;
  letter-spacing: 0.1em;
  text-transform: uppercase;
}

.CTA-small {
  font-family: "Manrope";

  font-size: 12px;
  line-height: 150%;
  letter-spacing: 0.0625em;
  text-transform: uppercase;
}

.label-1-upper {
  font-family: "Manrope";

  font-size: 14px;
  line-height: 150%;
  letter-spacing: 0.0625em;
  text-transform: uppercase;
}

.label-1-low {
  font-family: "Manrope";

  font-size: 14px;
  line-height: 150%;
}

.label-2-small {
  font-family: "Manrope";

  font-size: 10px;
  line-height: 150%;
  letter-spacing: 0.02em;
  text-transform: uppercase;
}

.caption-medium {
  font-family: "Manrope";

  font-size: 12px;
  line-height: 150%;
  letter-spacing: 0.02em;
}

.caption-regular {
  font-family: "Manrope";

  font-size: 12px;
  line-height: 150%;
  letter-spacing: 0.02em;
}
